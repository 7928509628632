import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/bgimg.jpg');

const filters = [
    { label: "Ground Mount", filter: ".cat-2" },
    { label: "Roof Top", filter: ".cat-1" },
    
];

const projects = [
    {
        slug: 'projects',
        thumb: require('./../../images/projects/sonkhed2.jpg'),
        image: require('./../../images/projects/sonkhed2.jpg'),
        title: 'Tepsol 13.5MWp, Sonkhed, Maharashtra',
        description: ` `,
        filter: 'cat-2'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/manvat.jpg'),
        image: require('./../../images/projects/manvat.jpg'),
        title: 'Manvat 13.5MWp, Parbhani Maharashtra',
        description: ` `,
        filter: 'cat-2'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/yeoti2.jpg'),
        image: require('./../../images/projects/yeoti2.jpg'),
        title: 'Yeoti 13.5MWp, Solapur Maharashtra',
        description: ` `,
        filter: 'cat-2'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/bori.jpg'),
        image: require('./../../images/projects/bori.jpg'),
        title: 'Bori 13.5MWp, Parbhani, Maharashtra',
        description: ` `,
        filter: 'cat-2'
    },
    {
        slug: 'victory-precision',
        thumb: require('./../../images/projects/victory.jpg'),
        image: require('./../../images/projects/victory.jpg'),
        title: 'Victory Precisions Pvt. Ltd. 415kWp, Chakan,Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'sansera',
        thumb: require('./../../images/projects/sansera.jpg'),
        image: require('./../../images/projects/sansera.jpg'),
        title: 'Sansera Engineering Pvt Ltd., Chakan,Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'biorad',
        thumb: require('./../../images/projects/Biorad.jpg'),
        image: require('./../../images/projects/Biorad.jpg'),
        title: 'Biorad Medisys, Sus,Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/lbst.jpg'),
        image: require('./../../images/projects/lbst.jpg'),
        title: 'LBST Pvt Ltd 1.5MWp, Haldia, West Bengal',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/azur.jpg'),
        image: require('./../../images/projects/azur.jpg'),
        title: 'Azure Power, Bap-Rajasthan',
        description: ` `,
        filter: 'cat-2'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/enfinity.jpg'),
        image: require('./../../images/projects/enfinity.jpg'),
        title: 'Enfinity Global, Maharashtra',
        description: ` `,
        filter: 'cat-2'
    },   
    {
        slug: 'advik-b',
        thumb: require('./../../images/projects/advik_b.jpg'),
        image: require('./../../images/projects/advik_b.jpg'),
        title: 'Advik Hi-Tech Pvt. Ltd., Bengaluru',
        description: ` `,
        filter: 'cat-1'
    },   
    {
        slug: 'appl',
        thumb: require('./../../images/projects/appl.jpg'),
        image: require('./../../images/projects/appl.jpg'),
        title: 'APPL GOR Plastics India Pvt. Ltd. 148.2kWp, Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'Zamil',
        thumb: require('./../../images/projects/zamil.jpg'),
        image: require('./../../images/projects/zamil.jpg'),
        title: 'Zamil Steel Buildings India Pvt. Ltd., Ranjangaon,Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/awl.jpg'),
        image: require('./../../images/projects/awl.jpg'),
        title: 'AWL 500kWp, Haldia, West Bengal',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/ongoing.jpeg'),
        image: require('./../../images/projects/ongoing.jpeg'),
        title: 'NTPC RE Park, Khavda,Bhuj',
        description: ` `,
        filter: 'cat-2'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/ongoing.jpeg'),
        image: require('./../../images/projects/ongoing.jpeg'),
        title: 'Adani Green Energy Ltd, Khavda,Bhuj',
        description: ` `,
        filter: 'cat-2'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/ongoing.jpeg'),
        image: require('./../../images/projects/ongoing.jpeg'),
        title: 'Azure Power, Bap-Rajastan',
        description: ` `,
        filter: 'cat-2'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/ongoing.jpeg'),
        image: require('./../../images/projects/ongoing.jpeg'),
        title: 'Goldi Solar Pvt Ltd, Sachi-MP',
        description: ` `,
        filter: 'cat-2'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/CSMI.jpg'),
        image: require('./../../images/projects/CSMI.jpg'),
        title: 'CSMI Airport 40.40kWp, Mumbai',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/TVM.jpg'),
        image: require('./../../images/projects/TVM.jpg'),
        title: 'Technovision Auto components Pvt. Ltd, Jaysingpur,Kolhapur',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'advik-patnagar',
        thumb: require('./../../images/projects/advik.png'),
        image: require('./../../images/projects/advik.png'),
        title: 'ADVIK Hi-tech Pvt. Ltd., Pantnagar,Uttarakhand',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/neologic.png'),
        image: require('./../../images/projects/neologic.png'),
        title: 'Neologic Engineers Pvt. Ltd., Pune,Maharashtra',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/JFE.jpg'),
        image: require('./../../images/projects/JFE.jpg'),
        title: 'JFE SHOJI STEEL INDIA PVT LTD 402kWp, Pune,Maharashtra',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/ASTP.jpg'),
        image: require('./../../images/projects/ASTP.jpg'),
        title: 'Amar Sadanand Tech park 88.77kWp, Baner,pune,Maharashtra',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/ABZ.jpg'),
        image: require('./../../images/projects/ABZ.jpg'),
        title: 'AMAR BUSINESS ZONE 120kWp, Baner-Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/banerone.jpeg'),
        image: require('./../../images/projects/banerone.jpeg'),
        title: 'CrystalStar Landscapes LLP 43.50kWp, Baner-Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/atp.jpeg'),
        image: require('./../../images/projects/atp.jpeg'),
        title: 'AMAR TECH PARK 134.88kWp, Baner-Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/ap81.jpeg'),
        image: require('./../../images/projects/ap81.jpeg'),
        title: 'AMAR PRISTINE 81 228.15kWp, Mundawa-Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'HILD ENERGY PVT. LTD., Jaisalmer- Rajasthan',
        description: ` `,
        filter: 'cat-2'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'NTPC, `Kota- Rajasthan',
        description:  ` `,
        filter: 'cat-2'
    },
    
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Adani Power (through Iconic Electric Power Pvt. Ltd.), Kothagudem-Telangana',
        description: ` `,
        filter: 'cat-2'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'JK Petroenergy Pvt. Ltd., Koppal, Karnataka',
        description: ` `,
        filter: 'cat-2'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'AMAR TECH CENTER 134.88kWp, Vimannagar-Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Amar Business Bay, Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Mercedes Benz India Pvt. Ltd., Chakan,Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Vulkan Technologies Ltd., Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'SVF, Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Solvay Specialties India Private Limited, Panoli Gujarat',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/oetikar.jpg'),
        image: require('./../../images/projects/oetikar.jpg'),
        title: 'Oetiker India Pvt. Ltd. 412kWp, Khalapur,Maharashtra',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Vulkan Technologies Ltd., Pirangut,Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Western Heat & Forge  Pvt. Ltd., Chakan,Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'KK Nag Pvt. Ltd., Sanaswadi,Maharashtra',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/arofine.jpg'),
        image: require('./../../images/projects/arofine.jpg'),
        title: 'Arofine Polymers Pvt. Ltd., Chakan,Pune',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Jivdani Devi Mandir Trust, Mumbai,Maharashtra',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Dakshana Foundation, Pune,Maharashtra',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Engineering Technologies Pvt. Ltd., Sangli, Maharashtra',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Monte Ville, Pune,Maharashtra',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Shivtirth Society, Pune,Maharashtra',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Marol-2-Petrol Pump, Andheri,Mumbai,Maharashtra',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'Baxi Singh Petrol Pump, Kurla,Mumbai,Maharashtra',
        description: ` `,
        filter: 'cat-1'
    },
    {
        slug: 'projects',
        thumb: require('./../../images/projects/pic-1-old.jpg'),
        image: require('./../../images/projects/pic-1-old.jpg'),
        title: 'NHTC-Petrol Pump, Mumbai,Maharashtra',
        description: ` `,
        filter: 'cat-1'
    },
]
class ProjectClassic2 extends React.Component {
    componentDidMount() {
        function loadScript(src) {
            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        }

        loadScript('./assets/js/masonary.js');
    }

    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Always dedicated and devoted" pagename="Project Classic View" bgimage={bnrimg} />

                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        {/* GALLERY CONTENT START */}
                        <div className="section-content" style={{ margin: 'auto' }}>
                            {/* PAGINATION START */}
                            <div className="filter-wrap p-b30 text-center">
                                <ul className="filter-navigation inline-navigation masonry-filter link-style text-uppercase">
                                    <li className="active"><NavLink to={"#"} data-filter="*" data-hover="All">All</NavLink></li>
                                    {filters.map((item, index) => (
                                        <li key={index}><NavLink to={"#"} data-filter={item.filter} data-hover={item.label}>{item.label}</NavLink></li>
                                    ))}
                                </ul>
                            </div>
                            {/* PAGINATION END */}

                            {/* GALLERY CONTENT START */}
                            <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                                {projects.map((item, index) => (
                                    <div key={index} className={`${item.filter} masonry-item col-lg-12 col-md-12 col-sm-12 m-b30`}>
                                        <div className={`project-classic hover-shadow alternate-layout ${index % 2 === 0 ? '' : 'reverse'}`}>
                                            <div className="mt-box">
                                                <div className="mt-thum-bx img-center-icon mt-img-overlay2">
                                                    <img src={item.thumb} alt="" />
                                                    <div className="overlay-bx">
                                                        <div className="overlay-icon">
                                                            <NavLink to={"/ContactUs"}>
                                                                <i className="fa fa-external-link mt-icon-box-xs" />
                                                            </NavLink>
                                                            <a className="mfp-link" href={item.image}>
                                                                <i className="fa fa-arrows-alt mt-icon-box-xs" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-info bg-white p-a30">
                                                <NavLink to={`/${item.slug}`}>
                                                    <h4 className="mt-title m-b10 m-t0" data-hover={item.title}>{item.title}</h4>
                                                    <p className="m-b0">{item.description}</p>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* GALLERY CONTENT END */}

                            <div className="m-t50 text-center">
                                <NavLink to={"#"} className="site-button btn-effect">Load More</NavLink>
                            </div>
                        </div>
                        {/* GALLERY CONTENT END */}
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    }
}

export default ProjectClassic2;